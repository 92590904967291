<template>
  <div :class="{ home: isHome, home2: isHome2 }" class=" relative">
    <div :class="{ mobile_shine_bg1_1: isHome, mobile_shine_bg2_1: isHome2 }" class="absolute w-full h-full">&nbsp;</div>
    <div :class="{ mobile_shine_bg1_2: isHome, mobile_shine_bg2_2: isHome2 }" class="absolute w-full h-full">&nbsp;</div>
    <div :class="{ mobile_shine_bg1_3: isHome, mobile_shine_bg2_3: isHome2 }" class="absolute w-full h-full">&nbsp;</div>
    <div :class="{ mobile_shine_bg1_4: isHome, mobile_shine_bg2_4: isHome2 }" class="absolute w-full h-full">&nbsp;</div>
    <div :class="{ mobile_shine_bg1_5: isHome, mobile_shine_bg2_5: isHome2 }" class="absolute w-full h-full">&nbsp;</div>
    <div :class="{ '': isHome, mobile_shine_bg2_6: isHome2 }" class="absolute w-full h-full">&nbsp;</div>
    <div class="hamburger w-full h-full m-auto relative">
        <div class="carousel">
          <div id="homeCarousel" class="carousel-inner">
            <Slider ref="carousel" />
          </div>
        </div>
        <div class="hamburger-name-area-mobile absolute">
        <div id="typing" class="b-0" style="margin:0 auto;"><span id="typingtext"></span>
            <div class="real_prev w-12 h-12 block" style="z-index: 30; color: white; position: absolute; left: 10vw; top: 0; " @click="goToPrev" >&nbsp;</div>
            <div class="real_next w-12 h-12 block" style="z-index: 30; color: white; position: absolute; right: 10vw; top: 0;" @click="goToNext" >&nbsp;</div>
        <img class="m-auto" style="width: 195px;" src="@/assets/press_start.png" /></div>
        </div>
    </div>
  </div>

  
</template>

<style scoped >
#hideMe {
    -moz-animation: mobileChangeBack 0s ease-in 5s forwards;
    /* Firefox */
    -webkit-animation: mobileChangeBack 0s ease-in 5s forwards;
    /* Safari and Chrome */
    -o-animation: mobileChangeBack 0s ease-in 5s forwards;
    /* Opera */
    animation: mobileChangeBack 0s ease-in 5s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
@keyframes fade {
    from {
        opacity: 1.0;
    }
    50% {
        opacity: 0.0;
    }
    to {
        opacity: 1.0;
    }
}
@keyframes mobileChangeBack {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}
@-webkit-keyframes mobileChangeBack {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}
  .home{
    background-image: url('../../assets/mobile_home_bg01.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 100vh;
    overflow: hidden;
  }
  .mobile_shine_bg1_1{
    background-image: url('../../assets/mobile_home_bg01_food.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    animation: fade 3s infinite ease-in-out;
    animation-iteration-count: 1;
  }
  .mobile_shine_bg1_2{
    background-image: url('../../assets/mobile_home_bg01_english.png');
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: center 10%;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 2s;
    animation-iteration-count: 1;
  }
  .mobile_shine_bg1_3{
    background-image: url('../../assets/mobile_home_bg01_word1.png');
    background-repeat: no-repeat;
    background-position: center center;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 4s;
    animation-iteration-count: 1;
  }
  .mobile_shine_bg1_4{
    background-image: url('../../assets/mobile_home_bg01_word2.png');
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: center center;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 6s;
    animation-iteration-count: 1;
  }
  .mobile_shine_bg1_5{
    background-image: url('../../assets/mobile_home_bg01_word3.png');
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: center center;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 8s;
    animation-iteration-count: 1;
  }
  .home2{
    background-image: url('../../assets/mobile_home_bg02.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 100vh;
    overflow: hidden;
  }
  .mobile_shine_bg2_1{
    background-image: url('../../assets/mobile_home_bg02_food.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    animation: fade 3s infinite ease-in-out;
    animation-iteration-count: 1;
  }
  .mobile_shine_bg2_2{
    background-image: url('../../assets/mobile_home_bg02_english.png');
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: center center;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 2s;
    animation-iteration-count: 1;
  }
  .mobile_shine_bg2_3{
    background-image: url('../../assets/mobile_home_bg02_word1.png');
    background-repeat: no-repeat;
    background-position: top 35% left -4%;
    width: 100%;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 4s;
    animation-iteration-count: 1;
  }
  .mobile_shine_bg2_4{
    background-image: url('../../assets/mobile_home_bg02_word2.png');
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: right top 10%;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 6s;
    animation-iteration-count: 1;
  }
  .mobile_shine_bg2_5{
    background-image: url('../../assets/mobile_home_bg02_word3.png');
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: 50% 47%;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 8s;
    animation-iteration-count: 1;
  }
  .mobile_shine_bg2_6{
    background-image: url('../../assets/reallovss.png');
    background-size: auto auto;
    background-repeat: no-repeat;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 2s;
    animation-iteration-count: 1;
    background-position: bottom 18% right;
  }
  .hamburger-name-area-mobile{
    min-width: 300px;
    text-align: center;
    color: white;
    height: 8rem;
    width: 100%;
    bottom: 0px!important;
  }
  .box-shadow {
    -webkit-box-shadow: 0px 0px 6px 3px rgba(255,255,255,0.28); 
    box-shadow: 0px 0px 6px 3px rgba(255,255,255,0.28);
  }
  .text-shadow {
    text-shadow: 1px 1px 10px rgba(255, 255, 255, 0.8);
  }
  #typingtext {
    font-size:24px;
    line-height: 44px;
    letter-spacing: 0.1em;
    font-weight: 700;
    border-right: 2px solid transparent;
    animation: blink-caret .75s infinite;
    overflow: hidden;
    -webkit-text-shadow: 0px -1px 10px rgba(255,255,255,0.75);
    text-shadow: 0px -1px 10px rgba(255,255,255,0.75);
  }
  /* 光标闪啊闪 */
  @keyframes blink-caret {
    from, to { box-shadow: 1px 0px 0px 0px transparent; }
    50% { box-shadow: 1px 0px 0px 0px; }
  }
  .deliver-area {
    right: 155px;
    bottom: 115px;
  }
  .carousel {
    bottom: 30px;
  }
</style>

<script>
import Slider from '@/components/SliderMobile.vue';
import config from '@/config.js';
import axios from 'axios';

export default {
  name: 'HomeMobile',
  components: {
    Slider,
  },
  data () {
    return {
      allHamburgers : [],
      timeouts : [],
      isHome: false,
      isHome2: true,
      sliderCurrentHamburgerIndex: 0,
    }
  },
  methods: {

    typing: function(string, length) {

      for (var i=0; i<this.$data.timeouts.length; i++) {
        clearTimeout(this.$data.timeouts[i]);
      }

      let divTyping = document.getElementById('typingtext');

      let vm = this;
      
      if(length < string.length+1) {
          console.log(string.slice(0, length), length);
          divTyping.innerHTML = string.slice(0, length);
          this.$data.timeouts.push(setTimeout(vm.typing.bind(null, string, length+1), 150));
      }

    },
    goToPrev: function() {
      
        this.$store.state.currentHambergur = this.$store.state.currentHambergur - 1;
        if ( this.$store.state.currentHambergur < 0 ) {
          this.$store.state.currentHambergur = this.$store.state.currentHambergur + this.$data.allHamburgers.length;
        }

        let hamburgerName = this.$data.allHamburgers[this.$store.state.currentHambergur];
        this.typing(hamburgerName, 1);

        // Get the carousel ref
        // const carousel = this.$refs.carousel
        const carousel = this.$refs.carousel.$refs.carousel;
        // call the method
        carousel.prev()

        console.log(carousel.currentSlideIndex)
        
    },
    goToNext: function() {
        
        this.$store.state.currentHambergur = this.$store.state.currentHambergur + 1;
        if ( this.$store.state.currentHambergur >= this.$data.allHamburgers.length ) {
          this.$store.state.currentHambergur = this.$store.state.currentHambergur - this.$data.allHamburgers.length;
        }

        let hamburgerName = this.$data.allHamburgers[this.$store.state.currentHambergur];
        this.typing(hamburgerName, 1);

        // Get the carousel ref
        // const carousel = this.$refs.carousel
        const carousel = this.$refs.carousel.$refs.carousel;
        
        // call the method
        carousel.next()

        // console.log(carousel.data.currentSlide._value)
        // this.$data.sliderCurrentHamburgerIndex = carousel.data.currentSlide._value + this.$store.state.currentHambergur;
    },

  },
  watch: {
    sliderCurrentHamburgerIndex: {
      handler: function(newVal, oldVal) {

        console.log(oldVal);
        console.log(newVal);
        //this.$store.state.currentHambergur = newVal;

        let hamburgerName = this.$data.allHamburgers[newVal];
        this.typing(hamburgerName, 1);

      },
      deep: true,
    }
  },
  mounted(){
    
    // fetch data from API.

    this.$store.state.currentHambergur = 0;


    // fetch data from API endpoint: '/wp-json/wp/v2/lovssburger'
    const website_data = axios.get(config.apiEndpoint + '/wp-json/wp/v2/lovssburger');

    website_data.then((response) => {
      console.log(response.data);
      // set response.data.hamburgers to this.$data.allHamburgers
      let allHamburgers = [];
      for (var i=0; i<response.data.hamburgers.length; i++) {
        if ( i === 1 ) {
          allHamburgers.push("十樓層漢堡");
        }
        allHamburgers.push(response.data.hamburgers[i].name);
        console.log(response.data.hamburgers[i].name);
      }
      
      this.$data.allHamburgers = allHamburgers;

      //
      let hamburgerName = this.$data.allHamburgers[this.$store.state.currentHambergur];

      this.typing(hamburgerName, 1);

      let random_boolean = Math.random() < 0.5;

      if ( random_boolean ) {
        this.$data.isHome = true;
        this.$data.isHome2 = false; 
        //alert(1);
      } else {
        this.$data.isHome = false;
        this.$data.isHome2 = true;
        //alert(2);
      }

      const carousel = this.$refs.carousel.$refs.carousel;


      setInterval(function () {
        this.$data.sliderCurrentHamburgerIndex = carousel.data.currentSlide._value;
      }.bind(this), 100);
      
    });

  }
}
</script>
