<template>
  <div class="home relative">


    <div class="hamburger w-full h-full m-auto relative">
        <div class="carousel">
          <div class="shine-background1a block absolute h-screen w-screen">&nbsp;</div>
          <div class="shine-background1 block absolute w-full h-full">&nbsp;</div>
          <div class="shine-background2 block absolute w-full h-full">&nbsp;</div>
          <div class="shine-background3 block absolute w-full h-full">&nbsp;</div>
          <div class="shine-background4 block absolute w-full h-full">&nbsp;</div>
          <div id="homeCarousel" class="carousel-inner">
            <Slider ref="carousel" />
          </div>
        </div>
        <div class="hamburger-name-area">
          <div id="typing" class="relative" style="padding-top: 16px;"><span id="typingtext"></span>
              <div class="real_prev w-12 h-12 block absolute" style="z-index: 30; color: white;  left: 15px; top: 20px; " @click="goToPrev" >&nbsp;</div>
              <div class="real_next w-12 h-12 block absolute" style="z-index: 30; color: white; right: 15px; top: 20px;" @click="goToNext" >&nbsp;</div>
          </div>
        </div>
    </div>
      <div class="deliver-area absolute">
        <a href="/Order"><img src="@/assets/deliver.jpg" /></a>
      </div>
  </div>
</template>

<style scoped>
@keyframes fade {
    from {
        opacity: 1.0;
    }
    50% {
        opacity: 0.0;
    }
    to {
        opacity: 1.0;
    }
}
  .home{
    background-image: url('../assets/home_page_background.jpg');
    background-size: cover;
    background-position: 50% 50%;
    height: 100vh;
    overflow: hidden;
  }
  .shine-background1{
    background-image: url('../assets/home_page_background-01.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% center;
    animation: fade 3s infinite ease-in-out;
    animation-iteration-count: 1;
  }
    .shine-background1a{
    background-image: url('../assets/home_food.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% center;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 2s;
    animation-iteration-count: 1;
  }
  .shine-background2{
    background-image: url('../assets/home_page_background-02.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% center;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 4s;
    animation-iteration-count: 1;
  }
  .shine-background3{
    background-image: url('../assets/home_page_background-03.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% center;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 6s;
    animation-iteration-count: 1;
  }
  .shine-background4{
    background-image: url('../assets/home_page_background-04.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 100% center;
    animation: fade 3s infinite ease-in-out;
    animation-delay: 8s;
    animation-iteration-count: 1;
  }
  .hamburger {
    height:700px;
  }
  .hamburger-pic{
    min-width: 800px;
    width: 800px;
    height:800px;
  }
  .hamburger-pic img{
    width: 100%;
  }
  .hamburger-name-area{
    min-width: 300px;
    max-width: 1440px;
    display: block;
    text-align: center;
    color: white;
    height: 90px;
    width: 500px;
    border: 2px solid rgba(255, 255, 255, .75);
    margin: -30px auto;
  }
  .box-shadow {
    -webkit-box-shadow: 0px 0px 6px 3px rgba(255,255,255,0.28); 
    box-shadow: 0px 0px 6px 3px rgba(255,255,255,0.28);
  }
  .text-shadow {
    text-shadow: 1px 1px 10px rgba(255, 255, 255, 0.8);
  }
  .hamburger .carousel {
    top: 5vh!important;
}
  #typingtext {
    font-size:34px;
    letter-spacing: 0.3em;
    font-weight: 700;
    border-right: 2px solid transparent;
    animation: blink-caret .75s infinite;
    overflow: hidden;
    -webkit-text-shadow: 0px -1px 10px rgba(255,255,255,0.75);
    text-shadow: 0px -1px 10px rgba(255,255,255,0.75);
  }
  /* 光标闪啊闪 */
  @keyframes blink-caret {
    from, to { box-shadow: 1px 0 0 0 transparent; }
    50% { box-shadow: 1px 0 0 0; }
  }
  .deliver-area {
    right: 155px;
    bottom: 115px;
  }
</style>

<style>
  .carousel__item {
    background-color: transparent !important;
  }
</style>

<script>
import Slider from '@/components/Slider.vue';
import config from '@/config.js';
import axios from 'axios';

export default {
  name: 'Home',
  components: {
    Slider,
  },
  data () {
    return {
      allHamburgers : [],
        timeouts : []
    }
  },
  methods: {
    typing: function(string, length) {
      
        for (var i=0; i<this.$data.timeouts.length; i++) {
          clearTimeout(this.$data.timeouts[i]);
        }

        let divTyping = document.getElementById('typingtext');

        let vm = this;
      
        if(length < string.length+1) {
            console.log(string.slice(0, length), length);
            divTyping.innerHTML = string.slice(0, length);
            this.$data.timeouts.push(setTimeout(vm.typing.bind(null, string, length+1), 110));
        }

        

    },
    goToPrev: function() {
      
        this.$store.state.currentHambergur = this.$store.state.currentHambergur - 1;
        if ( this.$store.state.currentHambergur < 0 ) {
          this.$store.state.currentHambergur = this.$store.state.currentHambergur + this.$data.allHamburgers.length;
        }
        //alert(this.$store.state.currentHambergur);

        let hamburgerName = this.$data.allHamburgers[this.$store.state.currentHambergur];
        this.typing(hamburgerName, 1);

        // Get the carousel ref
        // const carousel = this.$refs.carousel
        const carousel = this.$refs.carousel.$refs.carousel;
        // call the method
        carousel.prev()
        
    },
    goToNext: function() {
        
        this.$store.state.currentHambergur = this.$store.state.currentHambergur + 1;
        if ( this.$store.state.currentHambergur >= this.$data.allHamburgers.length ) {
          this.$store.state.currentHambergur = this.$store.state.currentHambergur - this.$data.allHamburgers.length;
        }
        //alert(this.$store.state.currentHambergur);

        let hamburgerName = this.$data.allHamburgers[this.$store.state.currentHambergur];
        this.typing(hamburgerName, 1);

        // Get the carousel ref
        // const carousel = this.$refs.carousel
        const carousel = this.$refs.carousel.$refs.carousel;
        
        // call the method
        carousel.next()
    }
  },
  mounted(){
    
    this.$store.state.currentHambergur = 0;

    

    // fetch data from API endpoint: '/wp-json/wp/v2/lovssburger'
    const website_data = axios.get(config.apiEndpoint + '/wp-json/wp/v2/lovssburger');

    website_data.then((response) => {
      console.log(response.data);
      // set response.data.hamburgers to this.$data.allHamburgers
      let allHamburgers = [];
      for (var i=0; i<response.data.hamburgers.length; i++) {
        allHamburgers.push(response.data.hamburgers[i].name);
        console.log(response.data.hamburgers[i].name);
      }
      
      this.$data.allHamburgers = allHamburgers;

      let hamburgerName = this.$data.allHamburgers[this.$store.state.currentHambergur];

      this.typing(hamburgerName, 1);
      
    });

  }
}
</script>
