<template>
    <header class="w-full fixed z-50">
        <!-- sidebar Start -->
        <div class="float-left menu-icon w-24">
            <a href="#"  @click="toggle" class="headerMenuToggle" ><img :class="{ isHide: isHide }" src="@/assets/menu-icon-mobile.png" /></a>
        </div>
        <div :class="{ isOpen: isOpen }" class=" ham-menu absolute grid items-center w-11/12 h-screen overflow-y-scroll text-white bg-black clear-both ">
            <div class="w-full px-8">
                <div class="grid w-full">
                  <div class="menu-icon w-24">
                      <a href="#"  @click="toggle" ><img src="@/assets/menu-icon-mobile.png" /></a>
                  </div>
                    <div><router-link @click="toggleShop" to="/mobile/shop" class="grid mx-auto my-3 items-center justify-center border-white border-2 box-shadow w-4/6 h-12 px-3 mt-2 mb-8 rounded hover:bg-white hover:text-black" >
                    <span class="ml-2 text-xl text-shadow font-bold racking-widest">店舖資訊</span>
                    </router-link></div>

                      <div :class="{ isOpen: isOpenShopMenu }" class="shop_menu grid " >
                        <router-link :class="{ hide: !this.$props.store_info[0].shop_name }" @click="toggleTheShop('banqiao')" to="/mobile/shop" class="grid mx-auto my-3 items-center justify-center border-white border-2 box-shadow w-4/6 h-12 px-3 mt-2 mb-8 rounded-3xl hover:bg-white hover:text-black" >
                        <span class="ml-2 text-xl text-shadow font-bold racking-widest">{{ this.$props.store_info[0].shop_name }}</span>
                        </router-link>
                        <router-link :class="{ hide: !this.$props.store_info[1].shop_name }" @click="toggleTheShop('xinzhuang')" to="/mobile/shop" class="grid mx-auto my-3 items-center justify-center border-white border-2 box-shadow w-4/6 h-12 px-3 mt-2 mb-8 rounded-3xl hover:bg-white hover:text-black" >
                        <span class="ml-2 text-xl text-shadow font-bold racking-widest">{{ this.$props.store_info[1].shop_name }}</span>
                        </router-link>
                        <router-link :class="{ hide: !this.$props.store_info[2].shop_name }" @click="toggleTheShop('jingmei')" to="/mobile/shop" class="grid mx-auto my-3 items-center justify-center border-white border-2 box-shadow w-4/6 h-12 px-3 mt-2 mb-8 rounded-3xl hover:bg-white hover:text-black" >
                        <span class="ml-2 text-xl text-shadow font-bold racking-widest">{{ this.$props.store_info[2].shop_name }}</span>
                        </router-link>
                        <router-link :class="{ hide: !this.$props.store_info[3].shop_name }" @click="toggleTheShop('sanduo')" to="/mobile/shop" class="grid mx-auto my-3 items-center justify-center border-white border-2 box-shadow w-4/6 h-12 px-3 mt-2 mb-8 rounded-3xl hover:bg-white hover:text-black" >
                        <span class="ml-2 text-xl text-shadow font-bold racking-widest">{{ this.$props.store_info[3].shop_name }}</span>
                        </router-link>
                        <router-link :class="{ hide: !this.$props.store_info[4].shop_name }" @click="toggleTheShop('neihu')" to="/mobile/shop" class="grid mx-auto my-3 items-center justify-center border-white border-2 box-shadow w-4/6 h-12 px-3 mt-2 mb-8 rounded-3xl hover:bg-white hover:text-black" >
                        <span class="ml-2 text-xl text-shadow font-bold racking-widest">{{ this.$props.store_info[4].shop_name }}</span>
                        </router-link>
                      </div>
                    <div><router-link @click="toggle" to="/mobile/about" class="grid mx-auto my-3 items-center justify-center border-white border-2 box-shadow w-4/6 h-12 px-3 mt-2 mb-8 rounded hover:bg-white hover:text-black" >
                    <span class="ml-2 text-xl text-shadow font-bold racking-widest">品牌故事</span>
                    </router-link></div>
                    <div><router-link @click="toggle" to="/mobile/News" class="grid mx-auto my-3 items-center justify-center border-white border-2 box-shadow w-4/6 h-12 px-3 mt-2 mb-8 rounded hover:bg-white hover:text-black" >
                    <span class="ml-2 text-xl text-shadow font-bold racking-widest">最新消息</span>
                    </router-link></div>
                    <div><router-link @click="toggle" to="/mobile/Order"  class="grid mx-auto my-3 items-center justify-center border-white border-2 box-shadow w-4/6 h-12 px-3 mt-2 mb-8 rounded hover:bg-white hover:text-black">
                    <span class="ml-2 text-xl text-shadow font-bold racking-widest">線上菜單</span>
                    </router-link></div>
                    <div @click="toggle2" to="mailto:lovssburger.co@gmail.com" class="grid mx-auto my-3 items-center justify-center border-white border-2 box-shadow w-4/6 h-12 px-3 mt-2 mb-8 rounded hover:bg-white hover:text-black">
                    <span class="ml-2 text-xl text-shadow font-bold racking-widest">客服專區</span>
                    </div>
                    <div class="relative items-center mx-auto my-0">
                        <div class="float-left w-12 mx-3"><a href="https://www.facebook.com/LovssBurger" target="_blank"  class="fa fa-facebook mr-4"></a></div>
                        <div class="float-left w-12 mx-3"><a href="https://www.instagram.com/lovsstogo/" class="fa fa-instagram"></a></div>
                        <div class="clear-both"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- sidebar End  -->
    </header>
</template>

<style scoped>

.fa {
  padding: 10px;
  font-size: 35px;
  width: 60px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.fa:hover {
    background-color: #FFFFFF;
    color: #000000;
}

.fa-facebook {
  color: #ffffff;
  border: 1px solid;
  border-color:#ffffff;
  border-radius: 50%;
}

.fa-instagram {
  color: #ffffff;
  border: 1px solid;
  border-color:#ffffff;
  border-radius: 50%;
}
.menu-area{
    height: 100px;
    z-index: 999;
}
.top-menu{
    margin-left: 10%;
    margin-right: 15%;
}
.shop-button{
    margin: 22px auto auto auto;
    border: 1px solid;
    font-size: 30px;
    font-weight: 700;
    border-radius: 30px;
    width: 150px;
    text-align: center;
}
.ham-menu {
    height: 100vh;
    transition: transform 0.5s linear;
    transform: translate(-110%);
}
.ham-menu.isOpen {
    transform: translate(0%);
}

.shop_menu {
  display: none;
}

.shop_menu.isOpen {
  display: block;
}

.isHide {
  opacity: 0;
}


</style>

<script>
export default {
  name: 'HeaderMobile',
  components: {
  },
  props: ['store_info'],
  data () {
    return {
      isOpen: false,
      isOpenShopMenu: false,
      isHide: false,
      banqiaoOpen: false,
      xinzhuangOpen: false,
    }
  },
  watch: {
        $route() {
            this.$nextTick(this.routeLoaded);
         }   
    },
  methods: {
    toggle: function() {
      
      console.log(this.$route)
      
      if ( this.$data.isOpen ) {
          this.$data.isOpen = false;
      } else {
          this.$data.isOpen = true;
      }

    },
    toggle2: function() {
      if ( this.$data.isOpen ) {
          this.$data.isOpen = false;
      } else {
          this.$data.isOpen = true;
      }
        window.location.href="mailto:lovssburger.co@gmail.com";
    },
    toggleShop: function() {
      if ( this.$data.isOpenShopMenu ) {
          this.$data.isOpenShopMenu = false;
      } else {
          this.$data.isOpenShopMenu = true;
      }

    },
    toggleTheShop: function(shopName) {
      this.$store.state.currentStore = shopName;
      if ( this.$data.isOpen ) {
          this.$data.isOpen = false;
      } else {
          this.$data.isOpen = true;
      }
    },
    routeLoaded: function() {
      // alert(this.$route.path)
      if (this.$route.path == '/mobile/News') {
        this.$data.isHide = true;
      } else {
        this.$data.isHide = false;
      }
    },
    
    banqiaoSwitch: function() {
        
        this.$store.state.currentStore = 'banqiao';

    },
    xinzhuangSwitch: function() {
        
        this.$store.state.currentStore = 'xinzhuang'

    },
    jingmeiSwitch: function() {
        
        this.$store.state.currentStore = 'jingmei'

    },
    sanduoSwitch: function() {
        
        this.$store.state.currentStore = 'sanduo'

    },
    neihuSwitch: function() {
        
        this.$store.state.currentStore = 'neihu'

    },
  },
  mounted(){
    this.$store.state.currentStore = ''
    
   // this.$router.onReady(() => this.routeLoaded());
      if (this.$route.path == '/mobile/News') {
        this.$data.isHide = true;
      } else {
        this.$data.isHide = false;
      }

  },
  updated() {
   
  }
}
</script>