<template>
    <header class="w-full fixed z-50" style="height:100px;">
        <nav class="menu-area w-full mx-auto z-20 relative flex justify-center">
            <div class="flex-none m-7 menu-icon w-11">
                <a href="#"  @click="toggle" ><img src="@/assets/menu-icon.png" /></a>
            </div>
            <div class="flex-none w-16 mt-4 ml-4">
                <a :href="this.$props.links.fb_link" target="_blank"  class="fa fa-facebook"></a>
            </div>
            <div class="flex-none w-16 mt-4 ml-6">
                <a :href="this.$props.links.ig_link" target="_blank" class="fa fa-instagram"></a>
            </div>
            
            <div class="flex-none w-36 ml-6 " :class="{ hide: !this.$props.store_info[0].shop_name }" >
                <router-link to="/Shop" ><div @click="banqiaoSwitch" :class="{ 'bg-black text-white border-black' : this.$store.state.currentStore === 'banqiao' }" class="shop-button hover:bg-black hover:text-white hover:border-black">{{ this.$props.store_info[0].shop_name }}</div></router-link>
            </div>

            <div class="flex-none w-36 ml-6 " :class="{ hide: !this.$props.store_info[1].shop_name }" >
                <router-link to="/Shop"><div @click="xinzhuangSwitch" :class="{ 'bg-black text-white border-black' : this.$store.state.currentStore === 'xinzhuang' }" class="shop-button hover:bg-black hover:text-white hover:border-black">{{ this.$props.store_info[1].shop_name }}</div></router-link>
            </div>
            
            <div class="flex-none w-36 ml-6 " :class="{ hide: !this.$props.store_info[2].shop_name }" >
                <router-link to="/Shop"><div @click="jingmeiSwitch" :class="{ 'bg-black text-white border-black' : this.$store.state.currentStore === 'jingmei' }" class="shop-button hover:bg-black hover:text-white hover:border-black">{{ this.$props.store_info[2].shop_name }}</div></router-link>
            </div>
            
            <div class="flex-none w-36 ml-6 " :class="{ hide: !this.$props.store_info[3].shop_name }" >
                <router-link to="/Shop"><div @click="sanduoSwitch" :class="{ 'bg-black text-white border-black' : this.$store.state.currentStore === 'sanduo' }" class="shop-button hover:bg-black hover:text-white hover:border-black">{{ this.$props.store_info[3].shop_name }}</div></router-link>
            </div>
            
            <div class="flex-none w-36 ml-6 " :class="{ hide: !this.$props.store_info[4].shop_name }" >
                <router-link to="/Shop"><div @click="neihuSwitch" :class="{ 'bg-black text-white border-black' : this.$store.state.currentStore === 'neihu' }" class="shop-button hover:bg-black hover:text-white hover:border-black">{{ this.$props.store_info[4].shop_name }}</div></router-link>
            </div>
            
            <div class="logo float-right m-4 ml-6">
                    <router-link to="/"><img src="@/assets/logo.png" style="max-width: none !important;" /></router-link>
                </div>
        </nav>
        <!-- sidebar Start -->
        <div :class="{ isOpen: isOpen }" class=" ham-menu flex flex-col items-center w-56 h-screen overflow-hidden text-white bg-black clear-both ">
            <div class="flex flex-col items-center w-full mt-36 px-8">
                <router-link @click="toggle" to="/about" class="flex items-center border-white border-2 box-shadow w-full h-12 px-3 mt-2 mb-8 rounded hover:bg-white hover:text-black" >
                <span class="ml-2 text-xl text-shadow font-bold racking-widest menu-text">品牌故事</span>
                </router-link>
                <router-link @click="toggle" to="/News" class="flex items-center border-white border-2 box-shadow w-full h-12 px-3 mt-2 mb-8 rounded hover:bg-white hover:text-black" >
                <span class="ml-2 text-xl text-shadow font-bold racking-widest menu-text">最新消息</span>
                </router-link>
                <router-link @click="toggle" to="/Order" class="flex items-center border-white border-2 box-shadow w-full h-12 px-3 mt-2 mb-8 rounded hover:bg-white hover:text-black">
                <span class="ml-2 text-xl text-shadow font-bold racking-widest menu-text">線上菜單</span>
                </router-link>
                <div @click="toggle2" to="mailto:lovssburger.co@gmail.com" class="flex items-center border-white border-2 box-shadow w-full h-12 px-3 mt-2 mb-8 rounded hover:bg-white hover:text-black">
                <span class="ml-2 text-xl text-shadow font-bold racking-widest menu-text">客服專區</span>
                </div>
            </div>
        </div>
        <!-- sidebar End  -->
    </header>
</template>

<style scoped>
.border-black {
    border-color: black !important;
}
.fa {
  padding: 10px;
  font-size: 35px;
  width: 60px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.fa:hover {
    background-color: #000000;
    color: #FFFFFF;
}

.fa-facebook {
  color: #000000;
  border: 1px solid;
  border-color:#000000;
  border-radius: 50%;
}

.fa-instagram {
  color: #000000;
  border: 1px solid;
  border-color:#000000;
  border-radius: 50%;
}
.menu-area{
    height: 100px;
    background-color: #F8AC00;
    z-index: 999;
}
.top-menu{
    margin-left: 10%;
    margin-right: 15%;
}
.shop-button{
    margin: 28px auto auto auto;
    border: 1px solid;
    font-size: 26px;
    font-weight: 700;
    border-radius: 30px;
    width: 140px;
    text-align: center;
}
.ham-menu {
    height: 100vh;
    transition: transform 0.5s linear;
    transform: translate(-110%);
}
.ham-menu.isOpen {
    transform: translate(0%);
}
.menu-text {
    letter-spacing: 7px;
    margin: 0 auto;
    text-indent: 7px;
}
</style>

<script>
export default {
  name: 'Header',
  components: {
  },
  props: ['store_info','links'],
  data () {
    return {
      isOpen: false,
      banqiaoOpen: false,
      xinzhuangOpen: false,
    }
  },
  methods: {
    toggle: function() {
      if ( this.$data.isOpen ) {
          this.$data.isOpen = false;
      } else {
          this.$data.isOpen = true;
      }

    },
    toggle2: function() {
      if ( this.$data.isOpen ) {
          this.$data.isOpen = false;
      } else {
          this.$data.isOpen = true;
      }
        window.location.href="mailto:lovssburger.co@gmail.com";
    },
    banqiaoSwitch: function() {
        
        this.$store.state.currentStore = 'banqiao';

    },
    xinzhuangSwitch: function() {
        
        this.$store.state.currentStore = 'xinzhuang'

    },
    jingmeiSwitch: function() {
        
        this.$store.state.currentStore = 'jingmei'

    },
    sanduoSwitch: function() {
        
        this.$store.state.currentStore = 'sanduo'

    },
    neihuSwitch: function() {
        
        this.$store.state.currentStore = 'neihu'

    },
  },
  mounted(){
    this.$store.state.currentStore = ''
    // settimeout 1s to wait for the store_info data
    // create settimeout funciton
    
    

  }
}
</script>