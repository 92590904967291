<template>
  
  <div v-if="!isMobile()">
    <Header :store_info="this.$data.store_info" :links="this.$data.links" />
  </div>
  <div v-else>
    <HeaderMobile :store_info="this.$data.store_info" :links="this.$data.links" />
  </div>
    <router-view 
      :store_info="this.$data.store_info" 
      :hamburgers="this.$data.hamburgers"
      :links="this.$data.links"
      :brand_story="this.$data.brand_story" 
    />
  <Footer/>
</template>

<style>
.hide, .grid.hide {
  display: none;
}
</style>


<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import HeaderMobile from '@/components/HeaderMobile.vue'

import config from '@/config.js';
import axios from 'axios';

export default {
  components: {
    Header,
    Footer,
    HeaderMobile
  },
  data() {
    return {
      store_info: [
        {
          shop_name: "",
          shop_photo1: "",
          shop_photo2: "",
          shop_photo3: "",
          shop_photo4: "",
          shop_photo5: "",
          phone: "",
          address: "",
          open_time: "",
          online_menu_qr_code_image: "",
          online_menu_link: "",
        },
        {
          shop_name: "",
          shop_photo1: "",
          shop_photo2: "",
          shop_photo3: "",
          shop_photo4: "",
          shop_photo5: "",
          phone: "",
          address: "",
          open_time: "",
          online_menu_qr_code_image: "",
          online_menu_link: "",
        },{
          shop_name: "",
          shop_photo1: "",
          shop_photo2: "",
          shop_photo3: "",
          shop_photo4: "",
          shop_photo5: "",
          phone: "",
          address: "",
          open_time: "",
          online_menu_qr_code_image: "",
          online_menu_link: "",
        },
        {
          shop_name: "",
          shop_photo1: "",
          shop_photo2: "",
          shop_photo3: "",
          shop_photo4: "",
          shop_photo5: "",
          phone: "",
          address: "",
          open_time: "",
          online_menu_qr_code_image: "",
          online_menu_link: "",
        },
        {
          shop_name: "",
          shop_photo1: "",
          shop_photo2: "",
          shop_photo3: "",
          shop_photo4: "",
          shop_photo5: "",
          phone: "",
          address: "",
          open_time: "",
          online_menu_qr_code_image: "",
          online_menu_link: "",
        }
      ],
      hamburgers: [
        {
          name: "",
          hamburger_image_desktop: "",
          hamburger_image_mobile: "",
        },
        {
          name: "",
          hamburger_image_desktop: "",
          hamburger_image_mobile: "",
        },
        {
          name: "",
          hamburger_image_desktop: "",
          hamburger_image_mobile: "",
        },
        {
          name: "",
          hamburger_image_desktop: "",
          hamburger_image_mobile: "",
        }
      ],
      links: {
        fb_link: "",
        ig_link: "",
        online_order_link: "",
        service_email_link: "",
      },
      brand_story: "",
    }
  },
  methods: {
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    setViewport: function() {
      let changeWindowSize = 375
      let viewportContent = "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no"
      if (window.innerWidth < changeWindowSize ) {
          viewportContent = "width=375,user-scalable=no,viewport-fit=cover"
      }
      document.querySelector("meta[name='viewport']").setAttribute("content", viewportContent)
    }
  },
  created() {
    this.setViewport();
  },
  mounted() {
    
    // fetch data from API endpoint: '/wp-json/wp/v2/lovssburger'
    const website_data = axios.get(config.apiEndpoint + '/wp-json/wp/v2/lovssburger');
    
    website_data.then((response) => {

      // this.$data.store_info = response.data.store_info;
      let store_info = response.data.store_info;
      for( let i=0; i<store_info.length; i++ ) {
        this.$data.store_info[i].shop_name = store_info[i].shop_name;
        this.$data.store_info[i].shop_photo1 = store_info[i].shop_photo1;
        this.$data.store_info[i].shop_photo2 = store_info[i].shop_photo2;
        this.$data.store_info[i].shop_photo3 = store_info[i].shop_photo3;
        this.$data.store_info[i].shop_photo4 = store_info[i].shop_photo4;
        this.$data.store_info[i].shop_photo5 = store_info[i].shop_photo5;
        this.$data.store_info[i].phone = store_info[i].phone;
        this.$data.store_info[i].address = store_info[i].address;
        this.$data.store_info[i].open_time = store_info[i].open_time;
        this.$data.store_info[i].online_menu_qr_code_image = store_info[i].online_menu_qr_code_image;
        this.$data.store_info[i].online_menu_link = store_info[i].online_menu_link;
      }
      this.$data.hamburgers = response.data.hamburgers;
      this.$data.links = response.data.links;
      this.$data.brand_story = response.data.brand_story;

    });

  },

}
</script>