<template>
  <Carousel ref="carousel" :wrap-around="true" >
    <Slide key=0 >
      <div class="carousel__item"><img class="pb-8" style="margin: 0 auto 7rem;" src="@/assets/hamburger_mobile.png" /></div>
    </Slide>
    <Slide key=1 >
      <div class="carousel__item"><img class="pb-8" style="margin: 0 auto 7rem;" src="@/assets/hamburger2.png" /></div>
    </Slide>
    <Slide key=2 >
      <div class="carousel__item"><img class="pb-8" style="margin: 0 auto 7rem;" src="@/assets/california_cheese_burger_mobile.png" /></div>
    </Slide>
    <Slide key=3 >
      <div class="carousel__item"><img class="pb-8" style="margin: 0 auto 7rem;" src="@/assets/jack_hamburger_mobile.png" /></div>
    </Slide>
    <Slide key=4 >
      <div class="carousel__item"><img class="pb-8" style="margin: 0 auto 7rem;" src="@/assets/dried_scallops_and_sea_urchins_burger_mobile.png" /></div>
    </Slide>
    <template #addons>
      <Navigation />
    </template>
  </Carousel>
</template>

<script>
//import { defineComponent } from 'vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default {
  name: 'Slider',
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  mounted(){
    
  }
} 
</script>

<style scoped>
  .real_prev{
    background-image: url('../assets/arrow_left.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
  .real_next{
    background-image: url('../assets/arrow_right.png');
    background-size: contain;
    background-repeat: no-repeat;
  }
.carousel__item {
  min-height: 200px;
  width: 100%;
  color:  var(--vc-clr-white);
  font-size: 20px;
  border-radius: 8px;
  justify-content: center;
  position: absolute;
  bottom: 0;
}

.carousel__slide {
  padding: 10px;
  align-items: flex-end !important;
  display: block; 
  position: relative;
  height: 92vh;
}

button.carousel__prev, button.carousel__next {
    display: none;
}
.carousel__prev,
.carousel__next {
  box-sizing: content-box;
  border: 2px solid white;
  z-index: 10 !important;
  background-color: transparent !important;
}
button.carousel__prev,
button.carousel__next {
  z-index: 10 !important;
}
.hamburger .carousel {
  position: relative;
  top: 1vh!important;
}
button.carousel__prev {
  top: 65vh;
  left: 15vw;
}
button.carousel__next {
  top: 65vh;
  right: 15vw;
}
</style>